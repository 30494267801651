import React from 'react';
import './Experience.css';

function Experience(){

    return (
            <div className='container center'>
                <div className='row'>
                    <h3 className='pad'>My Experience</h3>
                </div>
                <div className='row'>
                    <h4>2015</h4>
                </div>
                <div className='row'>
                    <h6>Weltschmertz - (company TRAFIK)</h6>
                </div>   

                <div className='row'>
                    <h4>2016</h4>
                </div>
                <div className='row'>
                    <h6>IGOWEGOYOU !? - (Thierry Smits)</h6>
                    <h6>Summer Collection by M.F. - (Orientheater dance company)</h6>
                    <h6>Faun - (Marina Granić &amp; Toni Flego)</h6>
                </div>    

                <div className='row'>
                    <h4>2017</h4>
                </div>
                <div className='row'>
                    <h6>Travellogue - (company TRAFIK)</h6>
                </div>   

                <div className='row'>
                    <h4>2018</h4>
                </div>
                <div className='row'>
                    <h6>Trittico d’autore ( Sharon Fridman, Valerio Longo, Paolo Mohovic )</h6>
                    <h6>Hopera ( E.sperimenti Dance Company )</h6>
                    <h6>InTrans ( Toni Flego & Ivona Medić )</h6>
                    <h6>Quanto Basta ( E.sperimenti Dance Company )</h6>
                    <h6>Halab ( Sol Pico )</h6>
                    <h6>Woe From Wit ( Sona Hovsepyan )</h6>
                </div>   

                <div className='row'>
                    <h4>2019</h4>
                </div>
                <div className='row'>
                    <h6>Trinacria ( E.sperimenti Dance Company )</h6>
                    <h6>Oxymoron ( Toni Flego )</h6>
                </div> 

                <div className='row'>
                    <h4>2020</h4>
                </div>
                <div className='row'>
                    <h6>In Museum ( Marie Chouinard )</h6>
                    <h6>Le Sacre du pritemps ( Xavier LeRoy )</h6>
                    <h6>The Mushroom at the End of the World ( Marco D’Agostin )</h6>
                </div> 

                <div className='row'>
                    <h4>2021</h4>
                </div>
                <div className='row'>
                    <h6>FAR ( Wayne McGregor )</h6>
                    <h6>Nemesis Vs Anubis Vs Everyone ( Mounir Saeed )</h6>
                    <h6>I am Alive ( Company TRAFIK )</h6>
                </div> 

                <div className='row'>
                    <h4>2022</h4>
                </div>
                <div className='row'>
                    <h6>Dear Friend, dream, breathe and move to think ( Toni Flego )</h6>
                    <h6>Songs: Regaining Lost Time ( Anna Kosiorowska )</h6>
                </div> 

                <div className='row'>
                    <h4>2023</h4>
                </div>
                <div className='row'>
                    <h6>From Off to On ( Iole La Sala & Toni Flego )</h6>
                    <h6>No More Birds On my Side ( Oriantheatre dance company )</h6>
                    <h6>How Can I Protect Myself From All This ? ( Toni Flego x Accademia Nazionale Di Danza Roma)</h6>
                </div> 
            
            </div>
    );
}

export default Experience;