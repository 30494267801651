import React from 'react';
import './Education.css';

function Education(){

    return (
            <div className='container'>
                <div className='row'>
                    <h1>Education</h1>
                </div>
                <div className='row'>
                    <h3>2011</h3>
                </div>
                <div className='row'>
                    <h6>Elementay School for classical ballet and contemporary dance Vežica - Highschool diploma for contemporary dance </h6>
                </div>   

                <div className='row'>
                    <h3>2016</h3>
                </div>
                <div className='row'>
                    <h6>Zagreb Dance Center - Structured practice for professional dancers (Thierry Smiths)</h6>
                </div>    

                <div className='row'>
                    <h3>2017</h3>
                </div>
                <div className='row'>
                    <h6>State Boarding School Colleggio Uccellis - Diploma in Dance </h6>
                </div>   

                <div className='row'>
                    <h3>2020</h3>
                </div>
                <div className='row'>
                    <h6>Biennale Collage Dance (Marie Chouinard) </h6>
                    <h6>Professional Dance Training - Recognized by Italian Ministry of Culture MIC (Padova Danza Project) </h6>
                </div> 

                <div className='row'>
                    <h3>2021</h3>
                </div>
                <div className='row'>
                    <div className='col'>
                        <h6>Biennale Collage Dance (Wayne McGreggor)</h6>
                        <h6>Yin Yoga Teacher Training</h6>
                    </div>
                    <div className='col right'>
                        <h2>Language skills</h2>
                        <h6>Croatian</h6>
                        <h6>Italian</h6>
                        <h6>English</h6>
                    </div>
                </div> 
                             
            </div>
    );
}

export default Education;