import React from 'react';
import './App.css';
import Education from './Education';
import Experience from './Experience';

function App() {
  return (
    <div className='scrollContainer'>
      <div className="bg first">
        <div className="container allign_elements_center">
          <img src={process.env.PUBLIC_URL + '/Tekst/ToniFlego.png'} className='opacity_width' />
        </div>
      </div>

      <div className="bg second">
        <div className="container allign_elements_center">
        <img src={process.env.PUBLIC_URL + '/Tekst/moveToThink.png'} className='opacity_width' />
        </div>
      </div>

      <div className="bg third scroll">
        <Education />
      </div>

      <div className='bg black'>
        <div className="container allign_elements_center">
          <video autoPlay loop controls>
            <source src="/Video/video1.mp4" type="video/mp4"/>
          </video>
        </div>
      </div>

      <div className="bg black scroll">
        <Experience/>
      </div>

      <div className="bg fourth scroll">            
        <div className="container allign_elements_center">
          
          <div className='row'>
            <div className='col-md-5'>
              <img src={process.env.PUBLIC_URL + '/Tekst/Projects.png'} className='img-responsive img-size' />
            </div>
            <span className='small-text'>After receiving the Italian National Award for Contemporary Dance (La Sfera Danza 2020), in 2023 Toni Flego has received an Independent artist status granted by the Republic of Croatia.</span>
          </div> 
        </div>
      </div>


      <div className="bg fifth">
        <div className="container h-100 d-flex flex-column">
          <div className='row flex-grow-1'>
           <div className='col'>
            <img src={process.env.PUBLIC_URL + '/Tekst/connect.png'} className='opacity_width' />
            </div>
          </div>
          <div className='row align-items-end flex-grow-1'>
            <div className='col-sm-3 ms-auto'>
              <a href="mailto:flego.tony@gmail.com">
              <img src={process.env.PUBLIC_URL + '/Tekst/mail.png'} className='img-responsive img-size right'/>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
